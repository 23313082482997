header{    
    //mmenu
    
    .navBox{
        @include position(fixed,$top:0%,$right:0);
        z-index: 990;
        min-width: 280px;
        background-color:rgba(black,0.85) ;
        height: 100vh;
        overflow:auto;
        display: none;
    }
    ul.mmenu{   
        width:100%;
        @include position(absolute,$top:50%,$left:0);
        transform: translateY(-50%);        
		> li{            
            text-align: center;
            a{
                position: relative;
                @include font-color(white,400,0em,1.2);
                @include font-size(pxToEm(16),pxToEm(24));
                display: inline-block;
                &:before{
                    content: ' ';
                    display: block;
                    background:no-repeat url(../images/pc_menu_icon.png) center left / 20px;
                    width: 20px;
                    height: 20px;
                    @include position(absolute,$left:-30px,$top:7px);
                    opacity: 0;
                    transition: opacity 0.6s;
                }
            }
            @include media-breakpoint-down(sm){                
                a{
                    padding: 10px 0;
                    margin:5px 0;
                    display: block;
                }
            }
            @include media-breakpoint-up(md){
                &:not(:last-child){
                    margin-bottom: 35px;
                }
                a:hover:before,
                &.active a:before{
                    opacity: 1;
                }
            }    
        }
    }//end mmenu  
    .menuicon{
        @include position(fixed,$right:0,$top:0);
        z-index: 998;
        .mpsrel{
            background-color: $acolor;
            border-bottom-left-radius:10px;
            @include media-breakpoint-down(xs){                
                width:50px;
                height: 50px;
            }
            @include media-breakpoint-up(sm){
                width:80px;
                height: 80px;
            }
        }
        .bar,.bar:after,.bar:before{
            transition: all .25s;
            content: "";
            position: absolute;
            left: 50%;	  
            top:50%;
            height: 2px;
            width: 32px;
            background:white;
            transform: translateX(-50%);
            @include media-breakpoint-down(xs){
                width:24px;
            }
        }
        .bar{
            &:before{
                top:-12px;   
            }
            &:after{
                top:12px;
            }
        }
    }
    // //mobile meun open
    &.menu-expanded .menuicon{
        .bar{
            background:rgba(black,0);            
            &:before{
                top:0px;
                transform: translateX(-50%) rotate(-45deg);
            }
            &:after{
                top:0px;  
                transform: translateX(-50%) rotate(45deg);
            }
        }
    }
}

//overwirte lightcase
#lightcase-case{
    padding:0 15px;
    iframe{
        max-width:calc(100vw - 30px) !important;
    }
}
#lightcase-overlay{
    background-color: rgba($color: #000000, $alpha: 0.8);
}
a[class*="lightcase-icon-"].lightcase-icon-close{
    // background: url(../svg/lighcase-close.svg) no-repeat center;    
    @include media-breakpoint-down(xs){
        width:20px;
        height: 20px;
        background-size: 20px;
    }
    @include media-breakpoint-up(sm){
        width:34px;
        height: 34px;
        background-size: 34px;
    }
    &:before{
        display: none;
    }
}

//slick next prev btn and dots
.slick-dots{
    //位置
    left:0;
    text-align: left;   
    li button::before{
        width:14px;
        height: 14px;
        background-color: $acolor;
        box-shadow: 0 0 8px 5px rgba($acolor,0);
        @include media-breakpoint-down(sm){
            transform: scale(0.8);
        }
    }
    li.slick-active button::before {
        background-color: white;
        box-shadow: 0 0 8px 5px rgba($acolor,1);
    }
}

.hiddendiv{
    display: none;
}
//overwrite plugin scroll
.mCSB_inside > .mCSB_container{
    margin-right: 16px;
}
.mCSB_scrollTools{
    opacity: 1;
}
.mCSB_scrollTools .mCSB_draggerRail{
    background-color: $blue;
    width:1px;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    width:7px;
    background-color: $blue !important;
    opacity: 1 !important;
}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
    background-color: black;
}
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar{
    opacity: 1 !important;
}

//plugin twzipcode
#twzipcode input{
    display: none;
}
//google recaptcha
.g-recaptcha{
    display: none;
}
.form_des{  
    @include font-color($yel,400,1.5);
    @include font-size(pxToEm(16),pxToEm(24));  
    @include media-breakpoint-down(xs){
        margin-bottom: 20px;
    }
    @include media-breakpoint-up(sm){
       margin-bottom: 60px;
    }
}
.formtable{
    
    .form-group{
        @include media-breakpoint-up(md){
            margin-bottom: 40px;
        }
    }
    .flex-2{
        @include media-breakpoint-down(sm){
            margin-bottom: 0;
            > div{
                margin-bottom: 1rem;
            }
        }
        @include media-breakpoint-up(md){
            display: flex;
            justify-content: space-between;
            > div{
                flex:0 0 calc(50% - 16px);
                width:calc(50% - 16px);
            }
        }
    }
    input,select,textarea{
        outline: none;
        appearance: none;
        box-shadow:none;
        border:none;
        width:100%;
        background-color:white;
        &:focus { outline: none; }
        @include font-color(black,400,1.5);
        @include font-size(pxToEm(16),pxToEm(18));  
        font-family: $mfont;
    }
    input,select{
        @include media-breakpoint-down(sm){
            padding:0 12px;
            height: 48px;
        }
        @include media-breakpoint-up(md){
            height: 60px;
            padding:0px 25px;
        }
    }
    textarea{
        @include media-breakpoint-down(sm){
            min-height: 241px;
            padding: 10px;
        }
        @include media-breakpoint-up(md){
            min-height: 320px;
            padding: 20px;
        }
    }
    @include placeholder {
        color:#666;
        opacity: 1;
    }
    //select
    select::-ms-expand { display: none; }    
    select{
        background:url('../images/select_down.png') white no-repeat calc(100% - 20px) 55% / 20px;
        // padding:3px 15px 3px 8px ;
        outline: none;
    }
    @media screen\0 {
        select{
        background:url("../images/select_down.png") white no-repeat calc(100% - 20px) 55% / 20px;
        }
    }
    label{
        text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.16);
        margin-bottom: 12px;
        @include font-color(white,500,1);
        @include font-size(pxToEm(16),pxToEm(28)); 
        i{
            color:$yel;
        }
        @include media-breakpoint-down(xs){
        }
        @include media-breakpoint-up(sm){
        }
    }
    .with-errors{
        flex:0 0 100%;
        min-height:30px;
        @include font-color($yel);  
        display:block;
        margin-top: 8px;
        @include font-color($yel,400,1);
        @include font-size(pxToEm(16),pxToEm(20)); 
    }
    .btn{
        width:270px;
        text-align: center;        
        @include font-color(black,400,0,1);
        @include font-size(pxToEm(16),pxToEm(28));
        margin-top: 15px;
        display: inline-block;
        position: relative;
        padding:22px 0;
        text-align: center;
        box-shadow: 3px 5px 6px rgba(0, 0, 0, 0.16);        
        background-color: white;
        &:hover{            
            background-color: $yel;
        }
    }
    //agree
    .check_radi {
        padding-left: 50px;
        line-height: 28px;
        text-align: left;
        width: auto;
        position: relative;
        cursor: pointer;
        user-select: none;
        @include media-breakpoint-down(xs){
            padding-left: 30px;
        }
        input {
            opacity: 0;
            cursor: pointer;
            @include position(absolute,$left:0,$top:0);
            z-index: 5;
            width:30px;
            height: 30px;
        }
        .checkmark {
            @include position(absolute,$top:0,$left:0);
            height: 30px;
            width: 30px;
            background-color: white;
            @include media-breakpoint-down(xs){
                transform: scale(0.7);
                transform-origin: left;
            }
        }
        .checkmark:after {
            content: "";
            @include position(absolute,$top:-8px,$left:8px);
            display: none;
            width: 28px;
            height: 30px;
            background: url('../images/agree_yes.png') no-repeat center / contain ;
        }
        input:checked~.checkmark:after {
            display: block;
        }
        a[data-popup]{
            color:$yel;
        }
    }
}
/*----------------------通用設定------------------------*/
//清除float浮動
%cf,main[role^=main]  {
    zoom: 1;
    &:before,
    &:after {
        content: "";
        display: table;
        height: 0;
        overflow: hidden;
    }
    &:after {
        clear: both;
    }
}
.clearfix {
  @include clearfix();
}

//selection
p,h1,h2,h3,h4,b,strong,span,li,div,a,img{
    &::selection{
          color:#fff;
          background:#000;
        }
}
//a tag
a{outline: none;}
a,a:hover {
  text-decoration: none;
  transition: 0.4s;
  color:initial;
}
*:focus {
    outline: none;
}
img {
    vertical-align: middle;
    max-width: 100%;
}
ul{
  list-style-type: none;
}
//border-box
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
// custom
.text-center{text-align: center;}
.text-right{text-align:right;}
.text-justify{text-align: justify;}
.mpsrel{position: relative;}
.hide{display:none;}
.bgcover{
  width:100%;
  height:0;
  background: center / cover;
}
.rowbox{
  margin:0 -15px 0 -15px;
  position: relative;
  max-width:$maxConentW;
  padding:0 $maxConentPding;
}

.row.col-md-center{
  justify-content: center;
}
@include media-breakpoint-down(md){
  &.show-lg{
    display: none;
  }
  .bgcover[data-src-lg]{
    content: attr(data-src-lg, url);
  }
}
@include media-breakpoint-up(lg){
  &.show-sm{
    display: none;
  }
  .bgcover[data-src-sm]{
    content: attr(data-src-sm, url);
  }
}
i.br-sm{
    @include media-breakpoint-down(md){
        display: block;
    }
    @include media-breakpoint-up(lg){
        display: none;
    }
}
.ftitle{
    @include font-size(pxToEm(30),pxToEm(50));
    &:not(.wh,.bk){
        @include font-color($acolor,900,0em,1.6);
    }
    &.wh{
        @include font-color(white,900,0em,1.6);
    }
    &.bk{
        @include font-color(black,900,0em,1.6);
    }  
}
.ftxt{
    @include font-color(#666,400,0em,1.6);
    @include font-size(pxToEm(18),pxToEm(24));
}
.fsub{
    &:not(.fbold){
        @include font-color(black,500,0em,1.6);
        @include font-size(pxToEm(20),pxToEm(28));
    }
    &.fbold{
        @include font-color(black,700,0em,1.6);
        @include font-size(pxToEm(20),pxToEm(28));
    }
}
.mainkv{
    width:100%;
    height: 100vh;
    margin:0 auto;
    position: relative;
    .bgcover{
        height: 100vh;
    }
    .dres{
        @include position(absolute,$left:0,$top:0);
        z-index: 10;
        background: no-repeat top left / contain;
        pointer-events: none;
        @include media-breakpoint-down(sm){
            width:100%;
            .mimg{
                max-width: 70%;
                @include position(absolute,$left:10%,$top:4%);
            }
        }
        @include media-breakpoint-only(sm){
            background-position: 0 -30%;
        }
        @include media-breakpoint-only(md){
            width:100%;
            .mimg{
                width: 60%;
                max-width: 450px;
                @include position(absolute,$left:7%,$top:4%);
            }
        }
        @include media-breakpoint-up(lg){            
            width:75%;
            min-width: 1024px;
            .mimg{
                max-width: 46%;
                @include position(absolute,$left:7%,$top:12%);
            }
        }
    }
    .slick-dots{
        @include media-breakpoint-down(sm){
            padding-left: 2%;
            li{
                margin: 0 9px;
            }
        }
        @include media-breakpoint-up(md){  
            bottom: 5%;
            padding-left: 4%;
            li{
                margin: 0 18px;
            }
        }
    }
}
.intro{
    .mainbx{
        background:no-repeat url(../images/02_dream/curve_bg.png) right bottom / contain;
        @include media-breakpoint-down(sm){
            padding-top: 50px;
        }
        @include media-breakpoint-up(md){
            padding-top: 100px;
        }
        h2 + div{            
            @include font-color($acolor,400,0em,1.6);
            @include font-size(pxToEm(24),pxToEm(36));
            @include media-breakpoint-down(sm){
                margin-top: 12px;
            }
        }
        .flex{
            position: relative;
            @include media-breakpoint-up(md){
                display: flex;
                padding:0 7%;
            }
            @include media-breakpoint-only(md){
                justify-content: center;
                flex-wrap: wrap;                
                margin-top: 60px;
            }
            @include media-breakpoint-up(lg){
                justify-content: flex-end;
                margin-top: 185px;
            }
        }
        .imgbx{            
            @include media-breakpoint-only(md){
                flex:0 0 100%;
                width:100%;
                
            }
            @include media-breakpoint-up(lg){
                @include position(absolute,$right:calc(610px + 7%),$bottom:-80px);
                width:1100px;
                max-width: 67vw;
            }
        }
        .txtbx{
            > div{
                display: flex;
                align-items: center;             
            }
            @include media-breakpoint-down(sm){
                padding:0 $pdr;
                .mimg{
                    flex:0 0 80px;
                    width:80px;
                    margin-right: 15px;
                }
                .mimg + div{
                    flex:0 0 calc(100% - 95px);
                    width:calc(100% - 95px);
                }
                > div{
                    margin-top: 30px;
                }
            }
            @include media-breakpoint-up(md){
                flex:0 0 620px;
                width:620px;                
                .mimg{
                    flex:0 0 110px;
                    width:110px;
                    margin-right: 15px;
                }
                > div{
                    margin-bottom: 65px;
                }
            }
            @media screen and (min-width:1400px){
                transform: translateX(-180px);
            }
        }
        .ftxt{
            @include media-breakpoint-down(sm){
                margin-top: 10px;                
            }
            @include media-breakpoint-up(md){
                margin-top: 18px;
            }
            color:#666;
            @include media-breakpoint-up(lg){
                color:white;
                text-shadow: 0 0 5px #333;
            }
        }
    }
    .photo{
        position: relative;
        @include media-breakpoint-down(md){ 
            overflow: hidden;  
            height: 500px; 
            > div{       
                height: 100%;
                overflow: hidden;
                @include position(absolute,$left:50%);
                transform: translateX(-50%);
                animation: image-move 15s linear infinite;
                img{
                    height: 100%;
                    max-width: initial;
                }
            }
        }
    }
    .imgtop{
        @include position(absolute,$left:0,$top:0);
        width:100%;
        height: 100%;
        opacity:0;
        // animation: opacityimg 3s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
}
@keyframes opacityimg{
    0%{opacity:1}
    20%{opacity: 1;}
    50%{opacity: 0;}
    90%{opacity: 0;}
    100%{opacity: 1;}
}
@keyframes image-move {
	0% {transform: translatex(-40%);}
	50% {transform: translatex(-60%);}
	100% {transform: translatex(-40%);}
}
.lot{
    background-image: linear-gradient(to bottom, #ffecec, #ffffff 50%, #ffffff);
    position: relative;
    @include media-breakpoint-down(sm){ 
        padding:50px 0 50px;
    }    
    @include media-breakpoint-only(md){
        padding:140px 0;
    }
    @include media-breakpoint-up(lg){
        padding:140px 0 275px;
    }
    &::before{
        content: ' ';
        width:1984px;
        height: 954px;
        @include position(absolute,$left:50%,$top:0);
        transform: translateX(-50%);
        background:no-repeat url(../images/04_6min/6min_bg_pattern.png);
    }
    .ftxt{
        @include media-breakpoint-down(sm){  
            margin: 10px 0;
        }
        @include media-breakpoint-only(md){
            margin:40px 0 60px;
        }
        @include media-breakpoint-up(lg){
            margin-bottom: 176px;
            margin-top: 40px;
        }
    }
    .slider-txt{
        background-color: rgba(white,0.9);
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
        position: relative;
        z-index:3;
        @include media-breakpoint-down(sm){            
            padding:24px 16px 40px;
            transform: translateY(30px);
        }
        @include media-breakpoint-only(md){
            padding:40px 24px 80px;
            margin:0 auto;
            max-width: 80%;
        }
        @include media-breakpoint-up(lg){
            transform: translateX(-10%);
            width:416px;
            height: 408px;
            padding:72px 64px;
        }
    }
    .slider-img{
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
        @include media-breakpoint-down(sm){
            width:calc(100% + 32px);
            transform: translateX(-16px);
        }
        
        @include media-breakpoint-up(lg){
            width:1024px;
            @include position(absolute,$right:-10%,$top:50%);
            transform: translateY(-50%);
        }
    }
    .slick-dots{
        @include media-breakpoint-down(sm){
            bottom:20px;
            padding-left: 6px;
        }
        @include media-breakpoint-only(md){
            padding-left: 14px;
            bottom:40px;
        }
        @include media-breakpoint-up(lg){
            bottom:64px;
            padding:0 64px;
        }
    }
    h6{
        @include font-color($acolor,700,0em,1.7);
        @include font-size(pxToEm(18),pxToEm(28));
        @include media-breakpoint-down(sm){
            margin-bottom: 10px;
        }
        @include media-breakpoint-up(md){
            margin-bottom: 20px;
        }
    }
    .mdes{        
        @include font-color(black,400,0em,1.6);
        @include font-size(pxToEm(18),pxToEm(24));
    }
}
.build{
    .txtbx{
        display: flex;
        align-items: center;
        opacity: 0;
        > div:nth-child(1){    
        }
        @include media-breakpoint-down(sm){
            margin-top: 15px;
            > div:nth-child(1){   
                margin-right: 15px;                          
                flex:0 0 60px;
                width:60px;
            }
        }
        @include media-breakpoint-up(md){ 
            margin-top: 30px;
            > div:nth-child(1){   
                margin-right: 20px;                          
                flex:0 0 80px;
                width:80px;
            }
        }
    }
    @include media-breakpoint-down(md){
        .sliderbx{
            margin:20px auto 140px;
        }
        .v1,.v2{
            display: none;
        }
        .slider{
            .slick-current {
                .txtbx{
                    opacity: 1;
                }
            }
        }
        .txtbx{
            transition: opacity 0.6s;
        }
    }
    @include media-breakpoint-down(xs){
        .sliderbx{
            margin:20px auto 50px;
        }
    }
    @include media-breakpoint-up(lg){        
        .sliderbx{
            width: 920px;
            margin: 50px auto 230px;
            position: relative;
        }
        .v1{
            transform: scale(.8) translateX(-60%);
            @include position(absolute,$left:0 ,$top:0 );
            z-index: -1;
        }
        .v2{
            transform: scale(.8) translateX(60%);        
            @include position(absolute,$left:0 ,$top:0);
            z-index: -1;
        }
        .slider{        
            transform-style: preserve-3d;
            perspective: 1000px; 
            .slick-track{
                width: 920px !important;
                height: 520px;
                transform: translate(0)!important;
            }
            .slick-list{
                overflow: initial;
            }
            .slick-slide {
                // transition: transform 1s;
                @include position(absolute !important,$left:0 !important,$top:0 !important);
                width:100% !important;
                // opacity: 0;
            }
            .slide-prev{
                transform: scale(.8) translateX(-60%);
                opacity: 1 !important;;
            }
            .slide-next {
                transform: scale(.8) translateX(60%);
                opacity: 1 !important;;
            }
            .slick-current {
                transform: scale(1) translateX(0);
                z-index: 10;
                opacity: 1 !important;
                .txtbx{
                    opacity: 1;
                }
            }
        }
    }    
}
.dampinp{    
    @include media-breakpoint-down(sm){
        margin-bottom:50px;
    }
    @include media-breakpoint-up(md){
        margin-bottom:140px ;
    }
    .flex{
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-down(xs){
            margin:40px 0 20px;
            > div{
                flex:0 0 80px;
                width: 80px;
            }
        }
        @include media-breakpoint-only(sm){
            margin:40px 0 20px;
        }
        @include media-breakpoint-up(md){
            margin:80px 0 40px;
        }
    }
    .txtbx{
        @include media-breakpoint-up(lg){
            max-width: 512px;
        }
    }
    .fsub{
        margin-bottom: 8px;
    }
    .mt{        
        @include media-breakpoint-down(sm){
            margin-top: 20px;
        }
        @include media-breakpoint-up(md){
            margin-top: 40px;
        }
    }
    .ftitle{
        @include media-breakpoint-down(sm){
            text-align: center;
        }
    }
}
.water{
    text-align: center;
    position: relative;
    background-color: #9bddeb;
    h2{
        width:100%;
        color:#033d53;
        @include position(absolute,$top:50px,$left:50%);
        transform: translateX(-50%);
    }
    @include media-breakpoint-down(md){
        padding-top: 170px;
    }
    @include media-breakpoint-only(lg){
        padding-top: 60px;
    }
    .mimg{
        width:100%;
        
    }
}
.construction{
    background:no-repeat url(../images/07_construction_method/construction_method_bg.jpg) center;
    @include media-breakpoint-down(sm){
        padding:30px 0;
    }
    @include media-breakpoint-only(md){
        background-size: cover;
    }
    @include media-breakpoint-up(md){
        padding:100px 0;
    }
    h6{
        @include font-color(black,700,0em,1.7);
        @include font-size(pxToEm(18),pxToEm(28));
        position: relative;   
        @include media-breakpoint-down(sm){     
            margin-bottom: 20px;
        }
        @include media-breakpoint-up(md){     
            margin-bottom: 30px;
        }
        &:after{
            content: ' ';          
            background-color: black;
            @include media-breakpoint-down(sm){
                width:26px;
                height: 2px;
                @include position(absolute,$left:0,$bottom:-8px); 
            }
            @include media-breakpoint-up(md){
                width:52px;
                height: 3px;
                @include position(absolute,$left:0,$bottom:-14px); 
            }
        }
    }
    .mtitle{        
        @include font-color($acolor,700,0em,1.4);
        @include font-size(pxToEm(18),pxToEm(38));
    }
    .slider-txt{
        @include media-breakpoint-down(sm){
            background-color: rgba(white,0.9);
            position: relative;
            z-index: 3;          
            padding:24px 16px 60px;
            transform: translateY(30px);
        }
        @include media-breakpoint-only(md){
            background-color: rgba(white,0.9);
            position: relative;
            z-index: 3;          
            padding:40px 24px 80px;
            margin:0 auto;
            max-width: 80%;
            transform: translateY(30px);
        }
        @include media-breakpoint-up(lg){     
            background-color: white;   
            width:518px;
            height: 423px;
            transform: translateX(-10%);
        }
    }
    .slider-img{
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
        @include media-breakpoint-down(md){
            width:calc(100% + 32px);
            transform: translateX(-16px);
        }
        @include media-breakpoint-up(lg){
            width:calc(100% - 400px);
        }
        @media screen and (min-width:1300px){
            width:822px;
        }
    }
    .slick-dots{
        @include media-breakpoint-down(sm){
            bottom:20px;
            padding-left: 6px;
        }
        @include media-breakpoint-only(md){
            bottom:40px;
            padding-left: 14px;
        }
        @include media-breakpoint-up(lg){ 
            bottom:72px;
        }
    }
    .v1{
        @include media-breakpoint-up(lg){
            .slider-txt{
                transform: translateX(-32px);    
                padding:72px 48px;
            }
            .slider-img{
                @include position(absolute,$right:-16px,$top:50%);
                transform: translateY(-50%);
            }
            .slick-dots{
                padding-left: 48px;
            }
        }
    }
    .v2{
        @include media-breakpoint-up(lg){
            .slider-txt{
                @include position(absolute,$right:-16px,$top:50%); 
                transform: translateY(-50%);                      
                padding:72px 48px 72px 120px;
            }
            .slider-img{
                transform: translateX(-32px);             
            }
            .slick-dots{
                padding-left: 120px;
            }
        }
    }
    .mtb{
        @include media-breakpoint-down(sm){
            margin:-20px 0 20px;
        }
        @include media-breakpoint-up(md){
            margin:110px 0 120px;
        }
    }
}
.pattern{
    background: no-repeat url(../images/08_structure/bg_image.jpg) center top / contain;
    @include media-breakpoint-down(sm){
        padding-top: 50px;
    }
    @include media-breakpoint-up(md){
        padding-top: 140px;
    }
    .ftxt{
        @include media-breakpoint-down(sm){
            margin:10px 0 20px;
        }
        @include media-breakpoint-up(md){
            margin:30px 0 50px;
        }
    }
}
.performance{
    h2{
        text-align: center;        
        @include media-breakpoint-down(sm){
            margin:50px 0 10px;
        }
        @include media-breakpoint-up(md){
            margin:140px 0 40px;
        }
    }
    .photo-group{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include media-breakpoint-down(sm){
            margin: 20px 0;
        }
        @include media-breakpoint-up(md){
            margin:70px 0 90px;
        }
        > div{  
            position: relative;
            margin-bottom: 50px;
            @include media-breakpoint-down(sm){
                width:100%;
                flex:0 0 100%;
            }
            @include media-breakpoint-up(md){  
                &.fleft{
                    flex:0 0 46%;
                    width:46%;
                }
                &.fright{
                    flex:0 0 50%;
                    width:50%;
                }
            }
        }
        .flex-2{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            > div{
                position: relative;
                @include media-breakpoint-down(sm){
                    flex:0 0 48%;
                    width:48%;
                }
                @include media-breakpoint-up(md){  
                    flex:0 0 46.65%;
                    width:46.65%;
                }
            }
        }
        .txt{
            @include font-color(white,400,0em,1);
            @include font-size(pxToEm(16),pxToEm(20));
            text-align: center;
            padding:16px 0;
            background-image: linear-gradient(to bottom, #fe1e70, #fe6997);            
            border-bottom-left-radius: 50px;
            border-top-right-radius: 50px;
            @include media-breakpoint-down(sm){
                @include position(absolute,$bottom:-28px,$left:calc(50% - 80px));
                width:160px;    
            }
            @include media-breakpoint-up(md){
                @include position(absolute,$bottom:-28px,$left:calc(50% - 100px));
                width:200px;    
            }
        }
    }
}
.formtable{
    background: no-repeat url(../images/10_form/bg.jpg) center top;
    @include media-breakpoint-down(sm){
        padding:50px 0;
    }
    @include media-breakpoint-up(md){        
        padding:98px 0;
    }
    h4{
        @include font-color(white,900,0em,1);
        @include font-size(pxToEm(30),pxToEm(50));
        text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.15);
        padding-bottom: 20px;
        margin-bottom: 32px;
        position: relative;
        display: inline-block;
        &:after{
            content:' ';
            @include position(absolute,$bottom:0,$left:0);
            box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.16);
            background-color: #ffffff;
            height: 5px;
            width:100%;
        }
    }
}
.infobox{
    .map{
        position: relative;
        @include media-breakpoint-down(xs){
            height: 350px;
            img{
                @include position(absolute,$top:50%,$left:50%);
                transform: translate(-50%,-50%);
                max-width: 1400px;
            }
        }
        @include media-breakpoint-up(sm){
            transform: translateY(-2px);
            img{
                @include position(absolute,$top:0,$left:50%);
                transform: translateX(-50%);
                max-width: 2000px;
            }
            height: 480px;
        }
    }
    .logo{
        width:300px;
        margin:0 auto 30px;
    }
    .connect-info{
        background: no-repeat url(../images/11_information/information_bg.jpg);
        padding:50px 8.3%;
        margin-top: -60px;
        .line{
            width:100%;
            height: 5px;
            box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.16);
            background-color: #ffffff;
        }        
        @include media-breakpoint-down(sm){
            display: none;
        }
    }
    .info{
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        a{
            box-shadow: 3px 5px 6px 0 rgba(0, 0, 0, 0.16);
            @include font-color(black,500,0em,1);
            @include font-size(pxToEm(24),pxToEm(24));
            text-align: center;
            transition: all 0.6s;
        }
        a span{
            background-color: white;
            color:black;
            display: block;
            padding:18px 0;
        }
        a.w30{
            flex:0 0 calc((100% - 72px) / 3);
            width:calc((100% - 72px) / 3);
            margin-bottom: 24px;
            @include media-breakpoint-up(md){                
                &:hover span{
                    background-color: black;
                    color:#fff100;
                    .svg{
                        fill:#fff100;
                    }
                }
            }
        }
        a.w100{
            background-color: white;
            display: flex;
            justify-content: space-between;
            flex:0 0 100%;
            width:100%;
            span{
                flex:0 0 calc((100% - 72px) / 3);
                width:calc((100% - 72px) / 3);
                background-color: #fff100;
                color:black;
            }
            i{
                padding:20px 0;
                flex:0 0 calc(100% - ((100% - 72px) / 3) );
                width:calc(100% - ((100% - 72px) / 3) );
            }
            @include media-breakpoint-up(md){                
                &:hover span{
                    background-color: black;
                    color:#fff100;
                    .svg{
                        fill:#fff100;
                    }
                }
            }
        }
        .svg{
            width:30px;
            fill:black;
            vertical-align: -5px;
            transition: fill 0.6s;
            margin-right: 12px;
        }
    }
    .build-info{
        @include media-breakpoint-down(sm){
            margin:24px 0 70px;
        }
        @include media-breakpoint-up(md){
            display: flex;
            justify-content: space-between;
            margin:48px 0 60px;
        }
    }
    ul.temp{
        li{
            display: flex;
            margin-bottom: 1.5em;
        }
        div{
            @include font-color(#666,500,0em,1);
            @include font-size(pxToEm(16),pxToEm(24));
        }
        i{
            position: relative;
            @include font-color(black,500,0em,1);
            @include font-size(pxToEm(16),pxToEm(24));
            margin-right: 1em;
            @include media-breakpoint-down(sm){
            }
            @include media-breakpoint-up(md){
                
            }
        }
        i:after{
            content:' ';
            @include position(absolute,$right:-0.5em,$top:0);
            width:2px;
            height: 1em;
            background-color: #666;
        }
    }
}
.f_info .infobtn{
    @include media-breakpoint-down(sm){
        @include position(fixed,$bottom:0);
        background-color: white;
        width:100%;
        z-index: 99;
        display: flex;
        justify-content: space-between;
        a{
            background-color: $acolor;
            flex:0 0 calc(25% - 1px);
            text-align: center;
            padding:18px 12px;
            @include font-color(white,400,0em,1);
            @include font-size(pxToEm(14),pxToEm(14));
        }
    }
    @include media-breakpoint-up(md){
        display: none;
    }
}
//-------------------------效果定義-------------------------//


//寬度
@mixin secction_width($width:100%, $max:1200px){
  width: $width;
  max-width:$max;
  margin: 0 auto;
  @extend %cf;
}

//圓角
@mixin border($bo:99em){
  -webkit-border-radius: $bo;
  -moz-border-radius: $bo;
  border-radius: $bo;
}
// 字型顏色
@mixin font-color($color,$weight:400,$spce:0,$lh:1.5) {
  color:$color;
  font-weight: $weight;
  line-height: $lh;
  letter-spacing: $spce;
}
//字型大小
//用法 @include font-size(pxToEm(16),pxToEm(36));
@mixin font-size($mobile,$desk) {
  @include media-breakpoint-down(xs){
    font-size: $mobile;
  }
  @include media-breakpoint-up(sm){
    font-size: $desk;
  }
}
//漸層色
@mixin gradient($direction, $list) { 
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient(to $direction, $list);  
}
//漸層色-動畫
@mixin gradient-animation($color1,$color2){
  background: $color1; /* Old browsers */
  background: -moz-linear-gradient(left, rgba($color1,1) 0%, rgba($color2,1) 20%, rgba($color2,1) 25%, rgba($color1,1) 45%, rgba($color1,1) 50%, rgba($color2,1) 70%, rgba($color2,1) 75%, rgba($color1,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba($color1,1) 0%, rgba($color2,1) 20%, rgba($color2,1) 25%, rgba($color1,1) 45%, rgba($color1,1) 50%, rgba($color2,1) 70%, rgba($color2,1) 75%, rgba($color1,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to left, rgba($color1,1) 0%, rgba($color2,1) 20%, rgba($color2,1) 25%, rgba($color1,1) 45%, rgba($color1,1) 50%, rgba($color2,1) 70%, rgba($color2,1) 75%, rgba($color1,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0 ); /* IE6-9 */
}
//動畫
@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
      @content;
  }
  @-moz-keyframes #{$animationName} {
      @content;
  }
  @-o-keyframes #{$animationName} {
      @content;
  }
  @keyframes #{$animationName} {
      @content;
  }
}
//placeholder
@mixin placeholder {
  ::-webkit-input-placeholder { @content;}
  :-moz-placeholder           { @content;}
  ::-moz-placeholder          { @content;}
  :-ms-input-placeholder      { @content;}
}
@mixin box-shadow($shadow...){ 
  -webkit-box-shadow:$shadow; 
  -moz-box-shadow:$shadow; 
  box-shadow:$shadow; 
}// @include box-shadow(12px 12px 0 $blue_u);

//------------------------- 限定行數  行高 行數(行高x行數)x1rem --------
@mixin clamp($lh,$clamp){
  height: ($lh*$clamp) * 1em;
  line-height: $lh;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $clamp; /* 第幾行要顯示點點點 */
  -webkit-box-orient: vertical;
}
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null){
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
//------------------------- triangle 三角形 方向 尺寸 顏色 -------------------------//

@mixin triangle($direction, $size, $borderColor ) { 
  content:""; 
  height: 0;
  width: 0; 
  @if $direction == top { 
    border-bottom:$size solid $borderColor; 
    border-left:$size dashed transparent; 
    border-right:$size dashed transparent; 
  } @else if $direction == right { 
    border-left:$size solid $borderColor; 
    border-top:$size dashed transparent; 
    border-bottom:$size dashed transparent; 
  } @else if $direction == bottom { 
    border-top:$size solid $borderColor; 
    border-left:$size dashed transparent; 
    border-right:$size dashed transparent; 
  } @else if $direction == left { 
    border-right:$size solid $borderColor; 
    border-top:$size dashed transparent; 
    border-bottom:$size dashed transparent; 
  } 
}


@mixin triangle_specially($direction, $height , $width, $borderColor){
content: "";
width: 0;
height: 0;
border-style: solid;
@if $direction == left_top{ //左上
    border-width: $height $width 0 0;
    border-color: $borderColor transparent transparent transparent;
} @else if  $direction == left_bottom{ //左下
    border-width: $height 0 0   $width;
    border-color: transparent transparent transparent $borderColor;
} @else if  $direction == right_top{ //右上
    border-width: 0 $width  $height  0;
    border-color: transparent $borderColor  transparent transparent;
} @else if  $direction == right_bottom{ //右下
    border-width: 0 0 $height  $width;
    border-color: transparent transparent $borderColor transparent;
} @else if  $direction == right_r { //右
    border-width: $height 0 $height $width;
    border-color: transparent transparent transparent $borderColor;
} @else if  $direction == left_f { //左
    border-width: $height $width $height 0;
    border-color: transparent $borderColor transparent transparent;
}
}
//等腰三角形
@mixin triangle_sosceles($direction, $height , $width, $borderColor){
content: "";
width: 0;
height: 0;
border-style: solid;
@if $direction == top{ //上
  border-width: 0 $width $height $width;
  border-color: transparent transparent $borderColor;
} @else if  $direction == bottom{ //下
  border-width: $height $width 0 $width;
  border-color:  $borderColor transparent transparent 
}@else if  $direction == left{ //左
  border-width: $height $width $height 0;
  border-color: transparent $borderColor transparent transparent;
}@else if  $direction == right{ //右
  border-width: $height 0 $height $width;
  border-color: transparent transparent transparent $borderColor;
}
}
//------------------------- px轉rem -------------------------//
@function pxToEm($px, $base: 16) { 
  @return ($px / $base) * 1rem; 
}
@function pxToRem($px, $base: 16) { 
  @return ($px / $base) * 1rem; 
}

//media query 
$pc-media:960px;
$pcs-media:959px;
$pad-media:760px;
$pads-media:759px;
$phone-media:480px;
$phones-media:479px;

//語系
.lang_tw{
    .en{
        display: none;
    }
    .tw{
        display: block;
    }
}
.lang_en{
    .en{
        display: block;
    }
    .tw{
        display: none;
    }
}